.bookingRoomPrice table {
    background-color: white;
    width: 100%;
}

.bookingRoomPrice .room_table,
.bookingRoomPrice .price_table {
    overflow: hidden;
}



.bookingRoomPrice th {
    height: 44px;
    background-color: #0A3A75;
    color: white;
    padding: .5rem .5rem;
    text-align: center;
    border: 1px solid gray;
    font-size: 14px;
}


.bookingRoomPrice td {
    height: 44px;
    padding: .5rem 1rem;
    text-align: center;
    border: 1px solid gray;
}

.bookingRoomPrice .priceInput {
    width: 100%;
    border: 1px solid gray;
    text-align: center;

}

.bookingRoomPrice .check-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.price_bulk_update_btn {
    display: flex;
    height: auto;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border: 1px solid transparent;

    border-radius: 12px;
    color: white;
    background-color: #0A3A75;
}

.price_bulk_update_btn:hover {
    color: #0A3A75;
    background-color: white;
    transition: all 0.2s ease-in-out;
    border: 1px solid #0A3A75;
}

.bookingRoomPrice button {
    margin: 10px;
}

.bookingRoomPrice .button_prev_next {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookingRoomPrice .button_prev_next i {
    cursor: pointer;
}

.bookingRoomPrice .form-group {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.bookingRoomPrice .form-control {
    width: auto;

}