.whatsapp-coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    /* background-color: #25d366; WhatsApp green color */
    color: black;
  }
  
  .whatsapp-coming-soon-content {
    text-align: center;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1.2em;
  }

.whatsappicon{
  height: 90px;
  width: 90px;
}

.whatsapp_bg_image{
      background-image: url("../Images/whtsap.jpeg");
    }