.ReservationDeskTable {
    margin-top: 2rem;
    width: 100%;
    /* table-layout: fixed; */
    /* Add this line */
}

.ReservationDeskTable th {
    background-color: #0A3A75;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    /* border-radius: 5px 5px 0 0; */
    border: none;
    text-align: center;
    padding: .5rem .3rem;
    top: 0;
    z-index: -1;
}

.ReservationDeskTable td {
    background-color: #fff;
    padding: .5rem .2rem;
    text-align: center;
    font-size: .9rem;
    font-weight: 400;
    border: 1px solid #ccc;
}

.btn-primary {border-radius: 8px;
border: 1px solid #0A3A75;
background: #0A3A75;}

.btn-primary:hover {
    transition: all .2s linear;
    background: white;
    color: #0A3A75;
}

.badge{
    padding: .5rem .3rem;
    text-align: center;
    font-size: .9rem;
    font-weight: 400;
}