.addslug {
    width: 100%;
    height: 100%;
}


.addslug th {
    background-color: #0A3A75;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    border-radius: 5px 5px 0 0;
    text-align: center;
    padding: .5rem;
    position: sticky;
    top: 0;
}

.addslug td {
    padding: .5rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid #ccc;
    background-color: white;
}

.addslug button {
    background: #fd5c01;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    border: none;
    border-radius: 5px;
    padding: .3rem .5rem;
}