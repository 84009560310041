.landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landingDiv img{
    width: 5rem;
}

.landingCardDiv {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    width: 100%;
}

/* .landingCanvasDiv {} */
.landingBookingDiv {
    width: 100%;
}

.landingActivitiesDiv {
    width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .landingCardDiv {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        width: 100%;
    }
}