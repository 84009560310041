.gallery {
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.gallery .navbar {
    list-style-type: none;
    padding: 0;
    display: flex;
    /* justify-content: center; */
}

/* .gallery .navbar li {
    margin: 0 1.5rem;
} */



.gallery .navbar button {
    padding: .2rem .5rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    border-radius: 5px;
    border: 1px solid #ccc;

}

.gallery.image-container {
    margin-top: 20px;
}

.gallery .image-gallery {
    display: flex;
    flex-wrap: wrap;
}

.gallery .image-gallery img {
    border-radius: 5px;
    margin: 10px;
    overflow: hidden;
    object-fit: cover;
    width: 300px;
    height: 200px;
    transition: .5s;
}

.gallery .image-gallery img:hover {
    transform: scale(1.1);

}

.active-category {

    color: orangered;
}