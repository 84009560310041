.BookingHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



/* --------------------------------------------------------
CMSBookingTable Css start
-------------------------------------------------------- */


.CmsBookingTab .nav-tabs {
    gap: 40px;
    border: 0;
}


.CmsBookingTab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #fff;
    background-color: rgb(10, 58, 117);
    border-color: rgb(10, 58, 117);

}

/* --------------------------------------------------------
BookingCustom Css start
-------------------------------------------------------- */

.cms_booking_setup {
    background-color: #fff;
    padding: 10px;
}

.BookingSetup_div {
    padding: .5rem;
    display: flex;
    justify-content: space-between;
}

.BookingSetup_div .facilities-lable {
    font-size: 10px;
}

.BookingSetup_div .form-check {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.BookingSetup_div .form-check-input {
    width: 10px;
    height: 20%;
}


.BookingSetup_div .scrollable-content {
    height: 20rem;
    overflow-y: auto;
}

.BookingSetup_div input {
    width: 60%;
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 8px;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    height: 40px;
}

.setup_content .imageUpload {
    padding: .5rem;
    display: flex;
    justify-content: space-between;
}


.setup_custon_imgDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.SetupInputDiv {
    display: flex;
    gap: 20px;
    width: 67%;
    justify-content: space-between;
}

.SetupImgDiv {
    width: 25%;
    height: 150px;
    padding: 20px;
}

.SetupImgDiv .previewImg {
    width: 100%;
    height: 100%;
    object-fit: fill;

}

/* --------------------------------------------------------
CMSBookingTable Css start
-------------------------------------------------------- */
.bCustom {
    width: 100%;
    height: auto;
    /* border: 2px solid red; */
    background: #ffff;
    border: 1px solid #E4E8EF;
    border-radius: 4px;
    padding: 40px 20px;
}

.c-color {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* border: 2px solid black; */
    gap: 18px;
    /* justify-content: space-between; */
}

.c-div {
    /* border: 2px solid darkblue; */
    width: 300px;
}

.b-form {
    width: 300px !important;
}

.b-btn {
    display: flex;
    width: 100%;
    padding: 10px 0;
    justify-content: center;
}

.choose-clr {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    border: 1px solid #E4E8EF;
    padding: 5px;
    gap: 10px;
}

.choose-clr input {
    border: 0;
    outline: 0;
}

.bmain {
    display: flex;
    justify-content: space-between;
}

.cont-in {
    /* display: flex; */
    flex-wrap: wrap;
    width: 100%;
    row-gap: 20px;
    column-gap: 40px;
}

.cont-in .content textarea {
    padding: 8px;
}

.cont-in .content h6 {
    margin: 10px 0;
}

.lbl_m {
    display: flex;
    width: 100%;
    column-gap: 20px;
    justify-content: space-between;
}

.cutm_lbl {
    display: block;
    width: 100%;
}

.in_lbl {
    margin: 10px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.in_lbl .lbl_impt {
    width: 80%;
    padding: 5px 10px;
    outline: 0;
    border: 1px solid #E4E8EF;
}



/* --------------------------------------------------------
CMSBookingTable Css start
-------------------------------------------------------- */

.BookingContent_Text {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
}






/* --------------------------------------------------------
Booking Price Package Css start
-------------------------------------------------------- */


.boking_pricepack {
    background-color: #fff;
    padding: 10px;
    height: 100%;
}

.priceinput_div {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}


.priceinput_div input {
    width: 80%;
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 8px;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    height: 40px;
}


.pricSubmitBtn {
    background: #0A3A75;
    color: #fff;
    padding: 0.4rem 0.7rem;
    border-radius: 8px;
    border: 0;
}


.priceCardMain{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    /* gap: 10px; */
}

.priceCard{
    display: flex;
    flex-direction: row;
    width: 48%;
    margin: 10px 0;
}

.priceCard img {
    width: 250px;
}