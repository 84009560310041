.reservationdesktable {
    margin-top: 2rem;
}

.filter {
    /* display: flex; */
    /* width: 50rem; */
    padding: 20px;
    /* justify-content: space-evenly; */
    gap: 20px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px 0;
    background-color: white;
}

.book-chk {
    width: 40%;
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 10px;
    padding: 5px 0;
}

.date-fltr {
    width: 100%;
    display: block;
    height: auto;
}

.dt-flt-inr {
    display: flex;
    gap: 40px;
    width: auto;
    margin: 10px 0;
}

.book-chk .dates {
    width: 100%;
    height: 2rem;
    padding-left: 10px;
    cursor: pointer;
    outline: 0;
    border: 1px solid #e9ecef;
    border-radius: 5px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    cursor: pointer;
    position: absolute;
    width: 100%;
    max-width: 280px;
}

.fltr-btn {
    display: flex;
    gap: 40px;
}

.fltr-btn .btn {
    background: #0A3A75;
    color: white;
    border: 1px solid #0A3A75;
}

.fltr-btn .btn:hover {

    transition: all .2s linear;
    background: white;
    color: #0A3A75;
}

.form1 {
    width: 300px;
}

.card-main {
    z-index: -1;
    width: 20rem !important;
    height: auto !important;
}

.card-body1 {

    display: flex;
    justify-content: center;
}

.checks-dates {
    display: inline-block;
}

.card-text1 {
    font-size: 2.2rem !important;
}

.card-foot {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 5px;
    height: 40px;
}

.oth-fltrs {
    display: flex;
    margin: 20px 0;
    gap: 20px;
}

.othr-fltr-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    width: 15rem;
    gap: 10px;
}

.flt-frm {
    display: flex;
    align-items: center;
    justify-content: center;
}


.othr-fltr-btn {
    display: flex;
    gap: 20px;
}

.form2 {
    width: 100px;
    font-size: 13.5px;
    padding: 0 8px;
}


/* table start  */


.tble-head {
    display: flex;
    /* gap: 20px; */
    margin: 10px 0;
    border: 1px solid #ccc;
    height: 4rem;
    align-items: center;
    text-transform: capitalize;
    background-color: white;
}

.tbl-inr {
    border-right: 1px solid #ccc;
    width: 200px;
    padding-left: 20px;
    text-transform: capitalize;
}

.booktble .b-head {
    text-transform: capitalize;
    text-align: center;
}

.b-tbody tr td {
    text-align: center;
}



/* if single booking  */

.sngle-bkng {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    text-transform: capitalize;
    align-items: center;
}

.sngle-bkng .text {
    display: block;
    width: auto;
    padding: 5px;
    margin: 10px 0;
    border: 1px solid gray;
}



/* pending confirmation page start  */

.prndg-dt-fltr {
    width: 50%;
}

/* 
.lght-btn {
    background: #dee2e6;
    color: black;
    border: 0;
    font-weight: 900;
} */