/* EditPopup.css */
.edit-popup-overlay {
    position: fixed;
    top: 5%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-popup {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    position: relative;
}

.edit-popup h3 {
    margin-top: 0;
}

.edit-popup label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.edit-popup input,
.edit-popup textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 10px;
}

.edit-popup button {
    background-color: #0A3A75;
    border: 1px solid #0A3A75;
    color: white;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
}

.edit-popup button:hover {
    background-color: white;
    color: #0A3A75;
    transition: all .2s ease-in-out;
}