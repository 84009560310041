.seomanagercard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.41);
    /* padding: 20px; */
    transition: 0.3s;
    width: 300px;
    margin-bottom: 3rem;
}

.seomanagercard:hover {
    transform: translateY(-10px);
}

.card-title {
    font-size: 1.5rem;
    margin: 0 0 10px;
}

.card-description {
    color: #666;
    font-size: 1rem;
    margin: 0 0 10px;
}

.card-keywords {
    font-size: 0.9rem;
    color: #007bff;
}

.seomanagercard button {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px 20px;
    width: 100%;
    /* height: 100%; */
}