.App {
  text-align: center;
}

body {
  background: #f5f5f5;
  font-family: "Poppins";
  font-family: "Poppins", serif;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Slide left Animation start*/
@keyframes slideLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-left {
  animation: slideLeft 2s ease-in-out;
}

/* Slide left Animation end*/

/* Slide Down Animation start*/

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-down {
  animation: slideDown 2s ease-in-out;
}

/* Slide Down Animation end*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Main CSS Start */

p {
  text-align: justify;
}

a {
  text-decoration: none;
}

.landingDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.top_main {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background: #fff;
  box-shadow: 0px 0px 5px #cccccc57;
  /* border: 2px solid; */
}

.main_sidebar {
  display: block;
  z-index: 11;
  position: fixed;
  width: 300px;
  top: 55px;
  height: 100%;
  padding-top: 35px;
  /* border: 2px solid yellow; */
}

.main-content {
  padding: 125px 30px 40px;
  position: relative;
  z-index: 10;
  height: 100%;
  margin-left: 300px;
  border: 2px soldi purple;
}

/* Save Btn CSS */

.Save_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Save_div button {
  background: #0a3a75;
  color: #fff;
  border-radius: 8px;
  border: 0;
}

::-webkit-scrollbar {
  display: none;
}

.saveBtn {
  background: #0a3a75;
  color: #fff;
}

.saveBtn:hover,
.saveBtn:focus {
  background: #0a3a75;
  color: #fff;
}

.addbtn {
  background: #0a3a75;
  color: #fff;
  padding: 0.4rem 0.7rem;
  border-radius: 8px;
  border: 0;
}

.deletebtn {
  background: #bb2d3b;
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  border: 0;
}
/* CSS */
.sassyInput {
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
  color: #333; /* Text color */
  background-color: #f9f9f9; /* Background color */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.sassyInput:hover {
  background-color: #e0e0e0; /* Lighter background color on hover */
}
