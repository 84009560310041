.paymentgateway {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 35px;
    height: auto;
    /* padding: 2rem 0; */
    flex-wrap: wrap;
}

.paymentgateway .c-crd-inr {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.23rem;
    height: 9rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    flex-shrink: 0;
    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.41);
    transition: .3s;
    cursor: pointer;

}


.paymentgateway .c-img {
    overflow: hidden;
    padding: 1rem;
    border-radius: 8px;
    width: 100%;

}

.paymentgateway button {
    padding: .3rem .5rem;
    border-radius: 8px;
    position: absolute;
    right: 6%;
    bottom: 10%;
    border: none;
    color: white;
    background: #fd5c01;
    opacity: .9;
}

.paymentgateway button:hover {
    opacity: 1;
    transition: all .2s ease-in-out;
}