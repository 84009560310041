.sportrow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .sportcol {
    height: 150px;
    border-radius: 24px;
    overflow: hidden;
  }
  
  .sportcol img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .calen {
    height: 320px;
    margin-top: 20px;
  }
  
  
  
  
  /* Time slot card  */
  .slotgrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .increment {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #F2F2F2;
    border-radius: 25px;
  }
  
  
  
  
  
  
  /* Time slot card  */
  
  
  
  
  
  
  
  
  
  
  
  
  
  .heading {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    padding: 16px 10px;
    color: #FFFFFF;
    
  
  }

  .bringBgColor{
  background-color: #AF5800;
  }
  
  .text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #333333;
  }
  
  .background {
    background-color: #F2F2F2;
  }
  
  .btn {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    border-radius: 44px;
  }
  
  .btn_back {
    background-color: #5092FB;
  
  }
  
  .footerTitle {
    color: #5092FB;
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
  }
  
  
  
  .link {
    text-decoration: none;
  }
  
  .icon {
    color: #5092FB;
  }
  
  .input {
    /* border: 2px solid #5092FB; */
    border-radius: 8px;
  }
  
  .divider {
    height: 18px;
    color: #333333;
    border: 1.1px solid #333333;
  }
  
  .condition {
    text-decoration: underline;
  }
  
  .borderBefore {
    border: 2px solid transparent;
  }
  
  .border-brown {
    border: 2px solid #E0E0E0;
  }
  
  
  .b1images {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .bimage {
      display: none;
    }
  
    .b1images {
      display: block;
    }
  }
  
  
  
  
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  
  .react-calendar_month-view_weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar_month-viewweekdays_weekday {
    padding: 0.5em;
  }
  
  .react-calendar_month-viewweekNumbers .react-calendar_tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar_month-viewdays_day--weekend {
    color: #d10000;
  }
  
  .react-calendar_month-viewdays_day--neighboringMonth,
  .react-calendar_decade-viewyears_year--neighboringDecade,
  .react-calendar_century-viewdecades_decade--neighboringCentury {
    color: #757575;
  }
  
  .react-calendar_year-view .react-calendar_tile,
  .react-calendar_decade-view .react-calendar_tile,
  .react-calendar_century-view .react-calendar_tile {
    padding: 2em 0.5em;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #ababab;
  }
  
  .react-calendar_month-viewdays_day--neighboringMonth:disabled,
  .react-calendar_decade-viewyears_year--neighboringDecade:disabled,
  .react-calendar_century-viewdecades_decade--neighboringCentury:disabled {
    color: #cdcdcd;
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  
  .react-calendar__tile--now {
    background: #e6e6e6;
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  
  .react-calendar__tile--active {
    background: #5092FB;
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
  .border {
    border: 2px solid #5092FB;
    border-radius: 4px;
    font-weight: 700;
    color: white;
    background-color: #5092FB;
  
  }
  
  .sportborder {
    border: 6px solid #27AE60;
  }
  
  .sportborderBefore {
    border: 6px solid transparent;
  }
  
  
  
  /* Calendar */
  
  
  .custom-calendar {
    font-family: Arial, sans-serif;
    border: none !important;
    border-radius: 8px;
  }
  
  
  @media screen and (max-width:320px) {
    .responsive {
      font-size: 14px;
      padding: 0 .5rem;
      border-radius: 20px;
      height: 100%;
      gap: 0;
    }
  
  
  }
  
  @media screen and (min-width:768px) {
    .custom-calendar {
      font-family: Arial, sans-serif;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  
  
  .react-calendar__month-view__weekdays abbr:where([title]) {
    text-decoration: none;
  }
  
  .react-calendar__navigation button:disabled {
    background-color: transparent;
  }
  
  .react-calendar__tile:disabled {
    background-color: transparent;
    color: #ababab;
  }