.Landingbookingtable {
    width: 100%;
    table-layout: fixed;
}

.Landingbookingtable th {
    background-color: #0A3A75;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 5px 5px 0 0;
    border: none;
    text-align: center;
    padding: .5rem;
    position: sticky;
    top: 0;
    z-index: -1;
}

.Landingbookingtable td {
    background-color: #fff;
    padding: .5rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid #ccc;
}