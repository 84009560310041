.Addpopup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: transparent;
    opacity: 1;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;

}

.Addpopup .userform {
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;

}

.Addpopup .heading {
    padding: 0 3.77rem;
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0A3A75;
    /* color: #ffffff; */
    /* background-color: #0A3A75; */
}

.Addpopup .adduserform {
    padding: 3rem 3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
}

.Addpopup input {
    padding: 0.5rem .5rem;
    width: 100%;
    margin-bottom: 2rem;
    border: 1px solid lightgray;
    border-color: #0A3A75;
}

.Addpopup .closebooking {

    position: relative;
    top: 0;
    left: 0;
    background-color: #ffffff;
    color: #0A3A75;
    cursor: pointer;
}

.confirmUser {
    width: 100%;
    border: 0;
    padding: 0.5rem .5rem;
    border-radius: 2px;
    color: white;
    background-color: #0A3A75;
}


.popupbg{
    overflow-y: auto; 
    max-height: 500px;
}