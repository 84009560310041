body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.highlightInput {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Add color transition */
  font-weight: bold;
}

.highlightInput:hover {
  background-color: #f0f0f0;
  color: #000; /* Change text color to black on hover */
}
