.book_card {
    box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.41);
}


.wb-crd {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
    /* padding-bottom: 2rem; */
    /* justify-content: space-around; */
}

.wb_card {
    width: 100%;
    height: 120px;
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
}

.wb_card i {
    font-size: 4rem;
    color: #c5d6d6;
}

.cms-icon {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.wb_card img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.wb_body {
    padding: 5px;

}

.addBtn {
    width: 70px;
    height: 40px;
}


.cmsDiv {
    background-color: #fff;
    padding: 0 10px;
}


.cmsTable {
    background-color: #fff;
    padding: 20px 10px;
}



/* 
---------------------------------------------------------

CMS Home CSS {Banner}

--------------------------------------------------------- */
.cmForm {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px;
    width: 100%;
    gap: 10px;
}

.cmsForm_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 10px 0;
}

.CmsNearImglabel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 150px;
}

.cmsimgdiv {
    flex-direction: row;
    justify-content: space-between;
}


.cmsForm_div .formInput {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    width: 100%;
    height: 40px;
}


.cmsForm_Btn {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

/* .fileImg {
    height: 40px;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
} */


.cmsimgdiv .upload {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
    border-radius: 13.854px;
    background: #EFF2F6;
    border: 0;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* line-height: 1.5; */
    overflow: hidden;
    position: relative;
}

.cmsimgdiv .upload input[type="file"] {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(3);
    height: 100%;
    overflow: hidden;
    border: 2px solid;
}

.CmsFooterDiv img {
    width: 250px;
    height: 150px;
    object-fit: cover;
}


.cmsForm_div .upl_img {
    background-color: #fff;
    border: 1px solid #eceaea;
    height: 150px;
    width: 250px;

}


.cmsForm_div .upl_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.imgDiv {
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.imgDiv img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}


.CmsBanner .carousal_div {
    width: 100%;
    height: 300px;
}

.CmsBanner .carousel {
    position: relative;
    width: 100%;
    height: 100%;
}

.CmsBanner .carousel-inner {
    position: relative;
    width: 100%;
    /* overflow: hidden; */
    height: 100%;
}


.CmsBanner .carousel-inner .carousel-item {
    height: 300px;
}

.CmsBanner .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cmsupldspn {
    color: #193971;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.cmsplusicon {
    color: #193971;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: capitalize;
}

/* Toggle Button Css  */

* {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

*:focus {
    outline: none;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    background-color: #f1f9f9;
}

.toggle-button-cover {
    display: table-cell;
    position: relative;
    width: auto;
    height: 40px;
    box-sizing: border-box;
}


.button-cover {
    height: 100px;
    margin: 20px;
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
}

.button-cover:before {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
}

.button-cover,
.knobs,
.layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

.button.r,
.button.r .layer {
    border-radius: 100px;
}

.button.b2 {
    border-radius: 2px;
}

.checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs {
    z-index: 2;
}

.layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}


/* Button 13 */
#button-13 .knobs:before,
#button-13 .knobs:after,
#button-13 .knobs span {
    position: absolute;
    top: 4px;
    width: 20px;
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    border-radius: 2px;
    transition: 0.3s ease all;
}

#button-13 .knobs:before,
#button-13 .knobs:after {
    color: #4e4e4e;
    z-index: 1;
}

#button-13 .knobs:before {
    content: "YES";
    left: 4px;
}

#button-13 .knobs:after {
    content: "NO";
    right: 4px;
}

#button-13 .knobs span {
    width: 45%;
    left: 37px;
    background-color: #03a9f4;
    z-index: 2;
    height: 60%;
}

#button-13 .checkbox:checked+.knobs span {
    left: 4px;
    background-color: #f44336;
}

#button-13 .checkbox:checked~.layer {
    background-color: #fcebeb;
}

/* ---------------------------------------------------------

CMS Home CSS {Body Image}

---------------------------------------------------------  */

.bodyimg_Div {
    width: 100%;
}

.bodyCardDiv {
    display: flex;
    gap: 20px;
    padding: 20px;
}


/* CMS Home CSS {Footer Links Start}  */

.socil_links {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

.links_div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 30%;
}

.footLinks {
    background-color: #fff;
    padding: 10px;
}

.socil_links .links_div input {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    height: auto;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 25px;
    width: 100%;
}

.Cmsfooter {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
}

.CmsFooterDiv {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
    width: 360px;
}

.CmsFooterDiv .cmsFootinput {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    height: auto;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
}

.footerRow {
    display: flex;
    gap: 20px;
}




/* ---------------------------------------------------------

CMS Teams Member CSS {Body Image}

---------------------------------------------------------  */


.teamCards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0;
}


.CmsTeamForm {
    display: block;
    padding: 20px 10px;
    background: #fff;
}

.TeamMemberDetail {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    flex-wrap: wrap;

}

.TeamSocialDetail {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    flex-wrap: wrap;
    row-gap: 10px;
}

.CmsTeamInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 32%;
}

.CmsTeamInput input {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    width: 100%;
    height: 40px;
}

.CmsTeamInputdiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.teamlabeldiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 150px;
}

.teamimgdiv {
    display: block;
    width: 200px;
    height: 150px;

    background-color: #fff;
    border: 1px solid #eceaea;
    /* border: 2px solid; */
}

.teamimgdiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* ---------------------------------------------------------

CMS About CSS 

---------------------------------------------------------  */

.cmsAboutDiv {
    background-color: #fff;
    /* min-height: 100vh; */
    height: auto;
    padding: 20px;
}

.cmsAboutForm {
    display: flex;
    padding: 10px;
    justify-content: space-between;
}

.cmsAboutHeading {
    display: flex;
    width: 50%;
    flex-direction: column;
    gap: 10px;
}

.cmsAboutHeadingInput {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    width: 80%;
    height: 40px;
}

.About_imgDiv {
    display: flex;
    width: 49%;
    justify-content: space-between;
}

.About_imgDiv img {
    width: 200px;
    height: 130px;
}

.about_section .About_imgDiv{
    width: 100%;

}

.cmsImgInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 150px;
}


.about_section{
    width: 100%;
    /* border: 2px solid; */
    padding: 20px;
}


.about_section .abt_inputDiv{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}


.about_section .abt_inputDiv input{
    width: 80%;
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    height: 40px;
}
/* ---------------------------------------------------------

CMS About CSS {CmsAbout_joditEditoore}

---------------------------------------------------------  */

.CmsAbout_joditEditoore {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    row-gap: 10px;
    width: 100%;
    height: auto;
}

.CmsAbout_joditEditoore .jodit-react-container {
    height: 100% !important;
}

.CmsAbout_joditEditoore .jodit-workplace {
    height: 100% !important;
}

.CmsAbout_joditEditoore .jodit-wysiwyg_mode {
    height: 100% !important;

}

/* ---------------------------------------------------------

CMS Advertisment CSS 

---------------------------------------------------------   */
.mainAdvr {
    min-height: 70%;
    height: auto;
    width: 100%;
    display: block;
    background-color: #fff;
    padding: 20px 10px;
}

.AdvrDiv {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    min-height: 80vh;
    height: auto;
}

.Advrinput {
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    gap: 10px;
    flex-direction: column;
}

.Advrinput input {
    width: 70%;
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    height: 40px;
}

.AdvrImginput {
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.adrlabeldiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 150px;
    height: 140px;
}

.adrimgdiv {
    display: block;
    width: 300px;
    height: 200px;
}

.adrimgdiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}


.QuetionDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.QuetionDiv .AdvrInput {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    height: 40px;
    width: 100%;
}


/* ---------------------------------------------------------

CMS Services CSS 

---------------------------------------------------------   */


.CmsServices {
    min-height: 70%;
    height: auto;
    width: 100%;
    display: block;
    background-color: #fff;
    padding: 20px 10px;
}

.CmsServicesForm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.CmsServicesHeading {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Service_imgDiv {
    width: 49%;
    display: flex;
    justify-content: space-between;
}

.ImgDivs {
    width: 49%;
    height: 150px;
}

.ImgDivs img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.CmsImgInput {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px;
    width: 150px;
    height: 140px;
}

.CmsServices_cards {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    width: 100%;
}

/* .CmsServices_cards .card {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 8rem;
} */

.CmsServices_cards .card .card-img-top {
    height: 200px;
}

/* .CmsServices_cards .card .card-body {
    width: 40%;
} */


/* ---------------------------------------------------------

CMS Restaurants CSS 

---------------------------------------------------------   */


.Cmsrestaurant {
    min-height: 70%;
    height: auto;
    width: 100%;
    display: block;
    background-color: #fff;
    padding: 20px 10px;
}

.CmsrestaurantForm {
    display: flex;
    flex-wrap: wrap;
}

.CmsrestaurantDiv {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}



.CmsServiceImgInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.CmsServiceHeadingInput {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    width: 95%;
    height: 40px;
}

.Cmsrestaurant_cards {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
}

.Cmsrestaurant_cards .card-img,
.card-img-top {
    height: 200px;
}

.CmsRestaurantImgInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 150px;
    height: 140px;
}

/* ---------------------------------------------------------

CMS Blogs CSS 

---------------------------------------------------------   */

.CmsBlogs {

    min-height: 70%;
    height: auto;
    width: 100%;
    display: block;
    background-color: #fff;
    padding: 20px;
}

.CmsBlogsForm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* border: 2px solid yellow; */
    padding: 10px;
    gap: 10px;
}

.CmsBlogsDiv {
    width: 49%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* border: 2px solid red; */

}

.BlogImgDiv {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* border: 2px solid darkblue; */
}

.CmsblogHeadingInput {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 0;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    width: 80%;
    height: 40px;
}

.CmsblogImgInput {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 150px;
}


.CmsBlogs_cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
}


/* .CmsBlogs_cards .card .card-img-top {
    width: 30%;
} */

/* .CmsBlogs_cards .card .card-body {
    width: 40%;
} */

.Cmsblog_joditEditoore {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Cms Nearby */



.CmsNearbyDiv {
    flex-direction: row;
}

.upldDiv {
    width: 150px;
    height: 120px;
}



/* CMS Events CSS  */

.EventMain {
    background-color: #fff;
    padding: 10px;
    height: 100%;
}


.eventmain_inputdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.eventdiv_cardDiv {
    display: flex;
    flex-wrap: wrap;
}



.eventmain_inputdiv input {
    width: 80%;
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 8px;
    box-shadow: none;
    color: #444;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    outline: 0;
    padding: 10px 15px;
    height: 40px;
}

.Event_imgDiv {
    display: flex;
    /* border: 2px solid red; */
    width: 100%;
    padding: 10px;
    height: auto;
    justify-content: space-between;

}

.Event_imgDiv .cmsImgInput{
    display: flex;
    width: 100%;
    /* border: 2px solid red; */
}

.Event_imgDiv {
    display: flex;
    width: 100%;
}

.evntupl_img{
    width: 200px;
    height: 200px;
}

.EventcmsImgInput{
    height: 100px;
    width: 150px;
}

.evntupl_img img{
    width: 200px;
    height: 200px;
}

.Event_imgDiv .Event_imgDiv{
    border: 2px solid;
    width: 300px;
}