* {
    margin: 0;
    padding: 0;
}

.landingCard {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

}




.landingCard .dashboard-stat {
    display: inline-block;
    padding: 0;
    height: auto;
    color: #fff;
    border-radius: 5px;
    width: 271px;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.41);

}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .landingCard .dashboard-stat {
        width: 295px;
    }
}

/* .landingCard .dashboard-stat.color-1 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2));
    background-color: #64bc36;
} */

.landingCard .dashboard-stat.color-2 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.1));
    background-color: #363841;
}

.landingCard .dashboard-stat.color-2 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.1));
    background-color: #0A3A75
}

/* .landingCard .dashboard-stat.color-3 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.3));
    background-color: #ffae00;
} */

/* .landingCard .dashboard-stat.color-4 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.1));
    background-color: #f3103c;
} */

.landingCard .dashboard-stat-content {
    position: absolute;
    left: 25px;
    top: 35%;
    width: 45%;
    transform: translateY(-50%);
}

.landingCard .dashboard-stat-content h4 {
    font-size: 42px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #fff;
    letter-spacing: -1px;
}

.landingCard .dashboard-stat-icon {
    position: absolute;
    right: 1px;
    font-size: 80px;
    line-height: 1;
    padding: 0 25px;
    top: 20px;
    opacity: 0.6;
}

.landingCard .im {
    font-family: 'iconsmind' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.landingCard .dashboard-stat-item p {
    text-align: center;
    margin-top: 8rem;
    border-top: 1px solid #eee;

    padding: .5rem 1.4rem;

}