.CmsProfile {

    padding: 0;
}

.links_div label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 17px;
}

.CmsProfile .Address {

    display: flex;
    flex-direction: column;
    padding-left: 3rem;

}

.CmsProfile .innerAdd {
    height: 100%;
    width: 90%;
}



.CmsProfile .CmsFooterDiv {
    width: 100%;
}

.CmsProfile .SocialLink {
    padding-right: 3rem;

}

.CmsProfile .profileimage {
    gap: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
}


.CmsProfile .adminDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* padding-left: 2rem; */
}

.CmsProfile .image {
    width: 160px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 50%;
    object-fit: cover;
}


.CmsProfile img {

    width: 100%;
}

.CmsProfile .Address input,
.CmsProfile .Social_link input {
    padding: .5rem 1rem;
    background-color: white;
    border: 1px solid #ccc;
    font-size: .9rem;
    color: #444;
    border-radius: 3px;
}

.CmsProfile .adminpannel {
    font-size: 1.5rem;
    margin-bottom: .3rem;
}

.CmsProfile .social_links {
    border: 2px solid green;
}

.CmsProfile .links_div {
    padding-top: .2rem;
    margin-top: .1rem;
    width: 100%;
}

.CmsProfile .profileimage {
    padding-left: 0;
    margin-left: 0;
}