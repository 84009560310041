* {
    margin: 0;
    padding: 0;
}

* {
    margin: 0;
    padding: 0;
    font-size: 100%;
    background: transparent;
    box-sizing: border-box;
}

.landingactivitiestable {
    margin-top: 4rem;
    outline: 0;

}





.landingactivitiestable .dashboard-list-box h4.gray {
    background-color: #0A3A75;
    color: #fff;
}

.landingactivitiestable .dashboard-list-box h4 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    padding: .7rem 3rem;
    color: #333;
    background-color: #fff;
    display: block;
    border-radius: 5px 5px 0 0;
}

.landingactivitiestable .dashboard-list-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
}

/* .landingactivitiestable .dashboard-list-box.with-icons ul li { */
/* padding-left: 87px; */
/* } */

.landingactivitiestable .dashboard-list-box ul li {
    padding: .522rem 3rem;
    border-bottom: 1px solid #eaeaea;
    transition: 0.3s;
    position: relative;
    margin-bottom: 0;
}

.landingactivitiestable ul li a {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.5;
    color: #fd5c01;
    position: relative;
    padding: 0;
}


.landingactivitiestable .sl {

    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.landingactivitiestable i {
    padding-right: 3px;
}

.landingactivitiestable strong {
    font-weight: 700;
}

.landingactivitiestable a.close-list-item {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    opacity: 0;
    padding: 4px;
    color: #ea2828;
    transition: 0.3s;
}

.landingactivitiestable a,
.landingactivitiestable button {
    outline: none !important;
}


.landingactivitiestable .dashboard-list-box .numerical-rating {
    margin: 0 3px;
}

.landingactivitiestable .numerical-rating.high {
    background-color: #64bc36;
}

.landingactivitiestable .numerical-rating {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
    line-height: 26px;
    height: 26px;
    width: 44px;
    display: inline-block;
    position: relative;
    letter-spacing: -0.5px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 15px 32px;
}

.landingactivitiestable .numerical-rating.mid {
    background-color: #c0c52a;
}


.landingactivitiestable .user-list ul li {
    padding: .5rem 2rem;
}



.landingactivitiestable img {
    max-width: 100%;
}

.landingactivitiestable .user-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.landingactivitiestable .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landingactivitiestable .user-list-content {
    display: flex;
    flex-direction: column;
}

.landingactivitiestable .user-list-content h3 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0 25px;
    background-color: #fff;
    display: block;
    border-radius: 4px 4px 0 0;
    text-align: start;
}


.landingactivitiestable .user-btns {

    float: right;
}

.landingactivitiestable .dashboard-list-box .button {
    padding: .4rem .5rem;
    line-height: 20px;
    font-size: 10px;
    font-weight: 400;
    margin: 0;
    margin-left: 5px;
}

.landingactivitiestable button.button,
.landingactivitiestable input[type="button"],
.landingactivitiestable input[type="submit"],
.landingactivitiestable a.button.border,
.landingactivitiestable a.button {
    background: rgb(253, 92, 1);
    top: 0;
    color: #fff;
    position: relative;
    font-size: 10px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin-right: 6px;
    overflow: hidden;
    border: none;
    border-radius: 5px;
    margin-left: 5px;

}