.reservationdeskbanner {
    padding: 0 5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    justify-content: center;
}

.reservationdeskbanner .btn {
    border-radius: 8px;
    border: 1px solid #0A3A75;
    background: #0A3A75;
}

.reservationdeskbanner .btn:hover {
    transition: all .2s linear;
    background: white;
    color: #0A3A75;
}




.reservationdeskbanner .card-1 {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
}

.reservationdeskbanner .card-1 h1 {
    padding: 1rem;
    text-align: center;
}

.reservationdeskbanner .cardDiv-2 {
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.reservationdeskbanner .cardDiv-2 h4 {
    display: flex;
    margin-bottom: 0;
    align-items: center;

}