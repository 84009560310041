* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* .main1 {
    padding-left: 10rem;
}
.ss {
    padding-left: -10rem;
} */
.c-main {
    display: flex;
    /* border: 2px solid red; */
    width: 100%;
    height: auto;
    justify-content: space-between;
    /* padding: 5px 30px; */
}

.ryt-fltr {
    display: flex;
    justify-content: space-evenly;
    /* border: 2px solid green; */
    gap: 50px;
}

/* aside {
    display: block;
    border: 2px solid yellow;
    width: 25%;
    margin-top: 4rem;
} */

section {
    display: block;
    /* border: 2px solid darkblue; */
    width: 75%;
    height: 100vh;
}

.c-child {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    /* border: 1px solid; */
    width: 100%;
    height: 100%;
    justify-content: space-around;
}

.c-ttle {
    color: #193971 !important;
    font-size: 18px;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    text-transform: capitalize !important;
}

.c-sub {
    color: #193971 !important;
    font-size: 4rem !important;
    font-family: Antonio !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30.948px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.f-text {
    color: #193971;
    text-align: center;
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 300 !important;
    line-height: normal;
    text-transform: capitalize;
}

aside .lmsg {
    color: #193971;
    font-size: 24px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

aside .lsocial {
    color: #193971;
    font-size: 18.789px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.lsub {
    color: #193971 !important;
    font-size: 18.362px;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
}

.msg-card {
    width: 15rem !important;
    height: auto !important;
    background: transparent !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    border-bottom: 3px solid #FD5C01 !important;
    border-radius: 0 !important;
}

.head {
    color: #193971;
    font-size: 24px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.at {
    font-weight: 400 !important;
}

.icon-main {
    width: 100%;
    height: auto;
    padding: 10px 0;
    display: flex;
    gap: 40px;
    /* border: 2px solid darkcyan; */
}

.sicon {
    font-size: 3rem;
}

.ancr {
    text-decoration: none;
    color: #193971;
    font-size: 15px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.ancr:hover {
    text-decoration: underline;
}

.s-nav {
    display: flex;
    width: 100%;
    height: 3rem;
    /* border: 2px solid red; */
    justify-content: center;
    align-items: flex-end;
    gap: 100PX;
}

.cm-icon {
    font-size: 1.7rem;
    color: #193971;
}

.cm-icon.active {
    color: #FD5C01;
}


/* Analytics css  */


.a-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.topAnlt {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    /* justify-content: center; */
    margin: 20px 0;
}

.topAnlt h3 {
    color: #193971;
    font-family: Inter;
    /* font-size: 28px; */
    font-style: normal;
    font-weight: 700;
    /* line-height: normal; */
    text-transform: uppercase;
}

.afaceicon {
    font-size: 3rem;
}

.acrds {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 30px;
}


/* Post start  */

.toppost h3 {
    color: #6F83A6;
    font-family: Inter;
    font-size: 39.167px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.postBtn {
    display: flex;
    /* border: 1px solid orange; */
    width: 100%;
    height: 5rem;
    justify-content: space-evenly;
    align-items: center;
}

.postBtn .pf-btn {
    width: 254.608px;
    height: 49px;
    flex-shrink: 0;
    border-radius: 157.569px !important;
    background: #F7F7F7;
    box-shadow: 1.9215686321258545px 0.9607843160629272px 2.882352828979492px 0px rgba(0, 0, 0, 0.39);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #193971;
    font-family: Inter;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    text-transform: uppercase;
    border: 0.961px solid #193971;
}

.postCrdm {
    display: block;
    width: 100%;
    height: auto;
}

.post-cmi {
    flex-direction: row !important;
    width: 100%;
    height: 15rem !important;
    border-radius: 20px !important;
    border: 1px solid #9FADC4 !important;
    justify-content: space-evenly !important;
    padding: 0 20px;
}

.postDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid green; */
    /* width: 33%; */
}

.post-icon {
    width: 10%;
}

.pc-body {
    flex-direction: column;
    justify-content: space-between;
}

.boost-btn {
    width: 145px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 20px !important;
    border: 1px solid #A6DD7C !important;
    box-shadow: 2px 1px 3px 0px rgba(0, 0, 0, 0.39) !important;
    background-color: transparent !important;
    display: flex;
    color: #039E00 !important;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    text-transform: capitalize;
    justify-content: center;
}

.post-crds {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px 0;
}

/* tools start  */

/* .form-check-input {
    width: 6rem !important;
    height: 3rem !important;
} */

.instacode {
    color: #fff;
    border-radius: 15px;
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

/* new post start  */

.newpost {
    display: flex;
    justify-content: space-between;
}

.post-ttle {
    color: #193971;
    font-family: Inter;
    /* font-size: 24px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin: 20px 0;
}

.post-para {
    color: #193971;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin: 20px 0;
}

.pt {
    text-align: justify;
    font-size: 18px;
}

.upload {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
    border-radius: 13.854px;
    background: #EFF2F6;
    border: 0;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* line-height: 1.5; */
    overflow: hidden;
    position: relative;
}

.upload input[type="file"]{
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(3);
    height: 100%;
    overflow: hidden;
    border: 2px solid;
}

.pst-upld {
    color: #193971;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.pst-pls {
    color: #193971;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: capitalize;
}

/* social reviews start  */

.post-rev {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    width: 100%;
}

.rev-div {
    display: flex;
    /* border: 2px solid red !important; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sr-body {
    /* border: 1px solid; */
    display: block;
}

.rev-icon {
    width: 30%;
}

.social_profile {
    width: 30%;
    gap: 10px;
}

.pr-inner {
    display: block;
    width: 7rem;
    height: 7rem;
    border: 2px solid;
    border-radius: 50%;
    background: #193971;
}

.social_profile span {
    color: #193971;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.rating {
    width: 30%;
}

.rating div {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    /* border: 2px solid green; */
    align-items: flex-end;
}


.rnt-str {
    font-size: 2rem;
    color: linear-gradient(180deg, #F2761C 0%, #FFC700 100%);
}

.rt-span {
    align-items: center !important;
}

/* ------------------------------------------------------------------
Social Analytics start  
------------------------------------------------------------------ */

.anly-card{
    width: 15rem;
    height: 12rem;
    border-radius: 14px;
    border: 1px solid rgb(159, 173, 196);
}

.anlycrd{

    display:flex;
    flex-direction:column;
     justify-content:space-around;
    align-items:center;
    height:100%;
}
.anly_titl{
    color: #193971;
    font-size: 18px;
    font-family: Inter ;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize ;
}
.anly_subtitl{
    color: #193971;
    font-size: 40px;
    font-weight: 700;
    font-family: Inter;
}


