#portfolio-flters li {
    display: inline-block;
    font-weight: 500;
    color: var(--dark);
    cursor: pointer;
    transition: .5s;
    border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
    color: var(--primary);
    border-color: var(--primary);
}

.portfolio-item img {
    object-fit: cover;
    height: 200px;
    transition: .5s;
}

.portfolio-item:hover img {
    transform: scale(1.1);
}

.portfolio-item .portfolio-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(98, 34, 204, .9);
    transition: .5s;
    opacity: 0;
}

.portfolio-item:hover .portfolio-overlay {
    opacity: 1;
}