/* Toast.css */
.Toast {
    position: absolute;
    top: 15%;
    left: 50%;
    width: 100%;
    height: 10vh;
    transition: .5s;
    transform: translateX(-50%);
    background-color: #32a852;
    color: white;
    padding: 10px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    z-index: 999;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    padding: 0 2rem;

}

.Toast.hidden {
    opacity: 1;
}

.Toast .close-icon {
    position: absolute;
    top: 0;
    right: 30px;
    cursor: pointer;
    font-size: 30px;
    color: white;
}