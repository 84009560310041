.analysissmallbox{
    border: 1px solid black;
}

.analysiscountbox{
    border: 2px solid grey;
    height: 50px;
    margin: 8px;
    background-color: white;
    padding: 1%;
    font-size: large;
    border-radius: 5px;
}

.selectfield{
    width: 15%;
    border: 2px solid black;
    margin: 10px;
    height: 40px;
    background-color: white;
}


