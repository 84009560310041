.overlayStyle {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

.popUpBG {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    border:1px solid #0A3A75;
}

.closeIcon{
  color: #fff !important;
  background-color: #fff;
  border-radius: 20px;
  padding: 2px;
  font-size: 18px;
}
.closeIcon:hover{
  cursor: pointer;
}