.loginMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    width: 400px;
    border-radius: 10px;
    padding: 20px;
}

.loginForm Form {
    width: 100%;
}

.googleLogin {
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: transparent;
    color: black;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid black;
    cursor: pointer;
}

.loginMain .eazotel-logo {
    /* border: 2px solid red; */
    color: red;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 10rem;
}

.loginMain .eazotel-logo .img_logo {
    width: 100%;
}

.loginMain .forget-pass {
    display: flex;
    justify-content: space-between;
}

.loginMain .forget {
    cursor: pointer;
    color: black;
}


.loginMain .passfield {
    position: relative;
}


.loginMain .eye-icon {
    position: absolute;
    top: 73%;
    left: 90%;
    transform: translateY(-50%);
    color: #aaa;
}

.loginMain .social-link {
    color: black;
}


.form-check svg{
    display: none;
}

.form-check button{
    width: 140%;
    height:100%;
    background-color: green !important;
    color: white;
    border-radius: 5px;
}