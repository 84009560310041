.channelmanager .main {
    display: flex;
    width: 100%;
    height: 142px;
    flex-shrink: 0;
    border-radius: 8px;
    box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.41);
}

.channelmanager .m-inner {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-right: 1px solid #ccc;
    background-color: white;
}

.channelmanager .m-inner span {

    color: #193971;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.channelmanager .edit {
    text-decoration: none;
    color: #193971;
}

.channelmanager .discnt {
    text-decoration: none;
    color: #FD5C01;
}

.channelmanager .h-id {
    font-weight: 300;
}

.channelmanager .add-chnl {
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #193971;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #193971;
    font-size: 1rem;
    padding: 10px 20px;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    text-decoration: none;
    font-family: 'Poppins', serif;
}

.channelmanager .add-chnl:hover {
    color: white;
    background-color: #193971;
    transition: all .2 s ease-in-out;
}

.channelmanager .ed-btn {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

/* cards start  */

.channelmanager .c-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 35px;
    height: auto;
    padding: 2rem 0;
    flex-wrap: wrap;
}

.channelmanager .c-crd-inr {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.23rem;
    height: 10rem;
    border-radius: 8px;
    /* border: 1px solid #ccc; */
    flex-shrink: 0;
    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.41);
    transition: .3s;

}

.channelmanager .c-crd-inr:hover {
    transform: translateY(-10px);
}

.channelmanager .c-img {
    width: 8rem;
    height: auto
}

.channelmanager .form-switch {
    position: absolute;
    left: 45%;
    bottom: 10%;
    width: auto;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .channelmanager .c-cards {
        grid-template-columns: repeat(3, 1fr);
    }

    .channelmanager .c-crd-inr {
        width: 20.3rem;
    }
}

@media screen and (min-width:768px) and (max-width: 1024px) {
    .channelmanager .c-cards {
        grid-template-columns: repeat(3, 1fr);
    }

    .channelmanager .c-crd-inr {
        width: 11.7rem;
    }

    .channelmanager .m-inner {
        padding: .5rem;
    }

    .channelmanager .m-inner span {
        font-size: 1.2rem;
    }
}


@media screen and (min-width:425px) and (max-width: 767px) {
    .channelmanager .c-cards {
        grid-template-columns: repeat(2, 1fr);
        justify-content: start;
    }

    .channelmanager .c-crd-inr {
        width: 12.4rem;
    }

    .channelmanager .m-inner {
        padding: .5rem;
    }

    .channelmanager .m-inner span {
        font-size: 1.2rem;
    }

    .channelmanager .ed-btn {
        flex-direction: column;
    }

    .channelmanager .m-inner span {
        text-align: start;
    }

}