a {
    text-decoration: none;
}



.main_topbar {
    width: 100%;
    display: flex;
    background-color: #ffff;
    z-index: 1;
}

.topbar {
    display: flex;
    width: 100%;
    height: 90px;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
}

.right-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.nav_search {
    display: flex;
    width: 350px;
    padding: 12px 0px 12px 40px;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    background: #F5F5F5;
}


.viewwebsite,
.viewengine {
    display: flex;
    height: auto;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid #0A3A75;
}

.viewwebsite {
    background: #0A3A75;
    color: #fff;
}

.viewwebsite:hover {
    color: #0A3A75;
    background: #fff;
    transition: all .2s ease-in-out;
}

.viewengine {
    color: #0A3A75;

}

.viewengine:hover {
    color: white;
    background: #0A3A75;
    transition: all .2s ease-in-out;
}

.notifybtn {
    border-radius: 12px;
    background: #EBF3FC;
    display: inline-flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.notifybtn:hover {
    background-color: transparent;
}

.notifybtn::after {
    content: none;
}

.sidebar-icon {
    color: blue
}

.sidebar-icon {
    color: white
}

.tn_search {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #DADADA;
    background: #F5F5F5;
}


.compny_name {
    color: #0A3A75;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 100% */
    text-transform: capitalize;
}

/* Topbar End  */


/* sidebar start  */

/* Figma css  */

.logo {
    width: 200px;
    height: 100%;
    flex-shrink: 0;
    /* border: 2px solid green; */
    display: flex;
    justify-content: center;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

aside {
    display: block;
    /* border: 2px solid green; */
    overflow-x: auto;
    overflow-y: visible;
    height: 100%;

}

.side_links {
    padding-top: 2rem;
    /* width: auto;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column; */
    /* position: fixed; */
    /* row-gap: 20px; */
    /* border: 2px solid red; */
    background: #fff;
    z-index: 2;
    padding-bottom: 5rem;
    /* height: 100%; */

}

.links {
    /* border: 2px solid darkblue; */
    align-items: center;
    display: flex;
    flex-direction: column;
    /* padding: 10px 20px; */
    row-gap: 5px;
    padding-bottom: 4rem;
}

.s_div {
    /* border: 2px solid red; */
    display: flex;
    width: auto;
    border: 2px solid red;

}

.s-link {
    display: flex;
    width: 250px;
    padding: 12px 20px 12px 20px;
    align-items: center;
    gap: 15px;
    color: #0A3A75;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;

}

.s-link:hover,
.s-link:focus {
    border-radius: 12px;
    background: #D0E3FB;
    /* color: #fff; */
}

.s-link.active {
    background-color: #0A3A75;
    color: #fff;
    border-radius: 12px;
}


.s-icon {
    fill: #0A3A75;
}

.s-icon.active {
    color: #fff;
    fill: #ffff;

}

.profile {
    display: flex;
    width: 240px;
    height: auto;
    padding: 4px 16px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #DFDFDF;
    background: #F5F5F5;
    margin-top: 10px;
}

.prfl_img {

    width: 45px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 77px;
    /* background: lightgray 50% / cover no-repeat;
     */
    border: 2px solid;
    background: no-repeat;
    overflow: hidden;
}

.prfl_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.d-link {
    padding: 0;
}

.d-link.active {
    padding-bottom: 0;
}

.drop_btn {
    width: 100%;
}

.drop_btn .dropdown-menu {
    background-color: #fff;
    inset: 0 auto auto 275px !important;
    margin: 0;
    border: 0;
    position: absolute;
    -webkit-transform: translate3d(0, -80px, 0) !important;
    transform: translate3d(0, -80px, 0) !important;
}

.s-link .accordion {
    width: 100%;
}

.s-link .accordion-button {
    display: flex;
    gap: 12px;
    font-size: 14px;
    width: 250px;
    padding: 12px 20px 12px 20px;
    align-items: center;
    color: #0A3A75;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;
    border-radius: 12px;
}

.s-link .accordion-body {
    padding-top: 5px;
    padding-bottom: 0;
}

.s-link .accordion-item:last-of-type {
    border: 0;
    outline: 0;
}

.s-link .accordion-button:not(.collapsed) {
    background-color: none;
}


.s-link .accordion-button:focus {
    background-color: #0A3A75;
    color: #fff;
    border-radius: 12px;
    outline: 0;
}

.SidebarAcc_ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.SidebarAcc_ul .st-link {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    color: #0A3A75;
    padding-left: 10px;
}

.SidebarAcc_ul .st-link:hover,
.SidebarAcc_ul .st-link:focus {
    background-color: #D0E3FB;
    /* color: #FFF; */
    border-radius: 5px;
    /* font-weight: 400; */
}

.SidebarAcc_ul .st-link.active {
    background-color: #0A3A75;
    color: #fff;
    border-radius: 8px;
}


.side_drop {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    color: #0A3A75;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    padding: 12px 20px 12px 20px;
    gap: 15px;
}


.side_drop:hover {
    background: transparent;
    color: #0A3A75;
}



.admName {
    color: #0A3A75;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: capitalize;
}

.admin {
    color: #4A4A4A;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 145.455% */
    text-transform: capitalize;
}